import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Toaster } from 'react-hot-toast';
import { RecoilRoot } from 'recoil';

import GlobalStyle from './globalStyles';
import MainWrapper from './layout/MainWrapper';
import { Calculator, Case, Home } from './routes';
import { defaultTheme } from './theme';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GlobalStateProvider } from './stateContext';
import Header from './layout/Header';
import Admin from './Admin';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <RecoilRoot>
      <GlobalStateProvider>
        <QueryClientProvider client={queryClient}>
          <MainWrapper>
            <ThemeProvider theme={defaultTheme}>
              <Router>
                <Header />                
                <p>**************************</p>
                <GlobalStyle />
                <Switch>
                  <Route path="/" exact>
                    <Home />
                  </Route>
                  <Route path="/case/:caseNumber/huskalkyl">
                    <Calculator />
                  </Route>
                  <Route path="/case/:caseNumber/entreprenad">
                    <Calculator />
                  </Route>
                  <Route path="/husmall/:templateCalculationId?">
                    <Calculator />
                  </Route>
                  <Route path="/case/:caseNumber">
                    <Case />
                  </Route>
                  <Route path="/admin">
                    <Admin />
                  </Route>
                </Switch>
              </Router>
              <Toaster />
            </ThemeProvider>
          </MainWrapper>
        </QueryClientProvider>
      </GlobalStateProvider>
    </RecoilRoot>
  );
}

export default App;
